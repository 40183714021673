import loader from '../loader.js';
import taskLoader from '../tasks/task-loader.js';

export default [
  {
    meta: { authRequired: true },
    path: '/learning-group/:groupId',
    name: 'learning-group',
    component: () => loader.load('home/learning-group.js'),
  },

  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/sequence-learning/:taskId',
    name: 'sl-task',
    props: true,
    component: () => taskLoader('tasks/sequence-learning/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/pal/:taskId',
    name: 'pal-task',
    props: true,
    component: () => taskLoader('tasks/pal/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/serialrt/:taskId',
    name: 'srt-task',
    props: true,
    component: () => taskLoader('tasks/serialrt/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/reversal/:taskId',
    name: 'reversal-task',
    props: true,
    component: () => taskLoader('tasks/reversal/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/associates/:taskId',
    name: 'associates-task',
    props: true,
    component: () => taskLoader('tasks/associates/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/iowa/:taskId',
    name: 'iowa-task',
    props: true,
    component: () => taskLoader('tasks/iowa/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/wisconsin/:taskId',
    name: 'wisconsin-task',
    props: true,
    component: () => taskLoader('tasks/wisconsin/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/matrices/:taskId',
    name: 'matrices-task',
    props: true,
    component: () => taskLoader('tasks/matrices/index.js'),
  },

  {
    meta: {
      authRequired: true,
    },
    path: '/qn/:taskId',
    name: 'qn-task',
    props: true,
    component: () => loader.load('tasks/qn/index.js'),
  },
];
