import { dataMethods, resultsComputed, resultsMethods } from '@state/helpers.js';
import template from './init-app.html';
export default {
  template,
  computed: {
    ...resultsComputed,
  },
  methods: {
    ...resultsMethods,
    ...dataMethods,
  },
  async mounted() {
    this.syncCount = 6; // bitwise flags

    this.unsubscribe = this.$store.subscribeAction({
      after: async ({ type }, state) => {
        switch (type) {
          case 'project/fetchProject':
            // all done so unsubscribe from other notifications

            await this.$store.dispatch('profile/fetchProfile');
            this.syncCount &= ~2; // clear the 2bit
            break;
          case 'data/setStatusUp':
            {
              console.log('status up');

              const allResults = await this.getAllResults();
              const clearable = [];

              if (allResults && allResults.length > 0) {
                for (const results of allResults) {
                  const { sessionInfo: { resumable } = {}, taskKey } = results;

                  if (!resumable) {
                    if (results.state !== 'prestart' && results.state !== 'pending') {
                      results.state = 'abandoned';
                    }

                    !taskKey.endsWith('state') && clearable.push(taskKey);
                  }
                  await this.save({ token: 'wiffle', value: results });
                }
              }

              if (clearable.length > 0) {
                for (const taskKey of clearable) {
                  await this.deleteResults(taskKey);
                }
              }

              this.syncCount &= ~4; // clear the 4bit
            }
            break;
        }
        if (this.syncCount == 0) {
          this.unsubscribe();
          this.$emit('init-app', { type: 'fetchProject' });
        }
      },
    });
  },
};
