import { registerModule } from '@tasks/task-state-base.js';

export default async function loadTask(url, name = 'default') {
  const stateUrl = `${url.slice(0, url.length - 8)}state/state.js`;

  const [{ default: component }, { taskStore, TaskStore, stateComputed, stateMethods }] = await Promise.all([
    import(/* webpackChunkName: "[request]" */ `~/${url}`),
    import(/* webpackChunkName: "[request]" */ `~/${stateUrl}`),
  ]);

  const vc = Object.create(component);

  const store = TaskStore ? new TaskStore().getModule() : taskStore;

  const stateMixin = {
    computed: {
      ...stateComputed,
    },
    methods: {
      ...stateMethods,
    },
    beforeCreate() {
      registerModule(store);
    },
  };

  (vc.mixins || (vc.mixins = [])).push(stateMixin);

  return vc;
}
