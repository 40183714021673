import api from '@state/api.js';
import timestring from 'timestring';

export default {
  namespaced: true,
  state: {
    currentProject: null,
    games: null,
  },
  mutations: {
    SET_CURRENT_PROJECT(state, newValue) {
      state.currentProject = newValue;
    },
    SET_GAMES(state, newValue) {
      state.games = newValue;
    },
  },
  getters: {
    // taskDurations(state, getters, rootState, rootGetters) {
    //   const id = rootGetters['session/userTask'];
    //   return state.currentProject[id];
    // },
    getGroupedGames(state) {
      return state.games;
    },
    getGames(state) {
      if (!state.games) {
        return [];
      }
      const all = [...state.games.values()].flatMap(game => {
        return game.tasks ? game.tasks : game;
      });

      return new Map(all.map(i => [i._id, i]));
    },
    getProjectId(state) {
      return state.currentProject && state.currentProject._id;
    },
    getProjectCode(state) {
      return state.currentProject && state.currentProject.code;
    },
    getMaxBadges(state, getters) {
      if (!state.games) {
        return 0;
      }

      const sum = [...getters.getGames.values()].reduce((p, { model: { points } }) => {
        const badgePoints = points?.badgePoints ?? 0;
        return p + badgePoints;
      }, 0);

      return Math.trunc(sum);
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    async init({ state, commit, dispatch, rootGetters }) {
      await dispatch('fetchProject');
    },

    async fetchProject({ state, commit, rootGetters }) {
      let projects;

      const userId = rootGetters['auth/userId'];
      if (userId) {
        try {
          projects = await api.get(`/api/project`).json();
        } catch (e) {
          projects = [];
        }
        // fixupDurations(project);
        const { games: gamesList = [], ...project } = (projects && projects[0]) || {};

        const games = new Map(gamesList.map(i => [i._id, i]));
        commit('SET_GAMES', games);
        commit('SET_CURRENT_PROJECT', project);

        return project;
      }
    },
  },
};

// ===
// Private helpers
// ===

const fields = [
  ['session.minDuration', 'min'],
  ['sentence.feedback', 'ms'],
  ['sentence.pause', 'ms'],
  'sentence.timeout',
  ['sentence.cue', 'ms'],
  ['sentence.firstCue', 'ms'],
  ['phoneme.feedback', 'ms'],
  ['phoneme.pause', 'ms'],
  'phoneme.timeout',
  ['phoneme.ISI', 'ms'],
];
// eslint-disable-next-line no-unused-vars
function fixupDurations(project) {
  fields.forEach(item => {
    let units = 's';
    if (Array.isArray(item)) {
      [item, units] = item;
    }
    item.split(/[.[\]]+/).reduce((prev, key, index, array) => {
      if (array.length === index + 1) {
        prev[key] = getDuration(prev[key], units);
      }

      return prev[key];
    }, project);
  });
}

function getDuration(value, units) {
  value = String(value);
  const groups = value
    .toLowerCase()
    .replace(/[^.\w+-]+/g, '')
    .match(/[-+]?[0-9.]+[a-z]+/g);
  if (!groups) {
    value = `${value}${units}`;
  }

  return timestring(value, 'ms');
}

// function getSavedState(key) {
//   return JSON.parse(window.localStorage.getItem(key));
// }

// function saveState(key, state) {
//   window.localStorage.setItem(key, JSON.stringify(state));
// }
