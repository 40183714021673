const namespaced = true;

const defaultTheme_ = 'board';

export default {
  namespaced,
  state: {
    theme: defaultTheme_,
  },

  mutations: {
    SET_THEME(state, newValue = defaultTheme_) {
      state.theme = newValue;
    },
  },
  getters: {},
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ commit, rootGetters }) {
      const userId = rootGetters['auth/userId'];
      const themeState = getSavedState(`theme-${userId}`);

      if (themeState) {
        commit('SET_THEME', themeState);
      }
    },

    setTheme({ commit, rootGetters }, newValue) {
      commit('SET_THEME', newValue);

      const userId = rootGetters['auth/userId'];
      saveState(`theme-${userId}`, newValue);
    },
  },
};

// ===
// Private functions
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
