const badgeGroups_ = {
  learning: ['SL', 'SRT', 'PAL'],
  flexibility: ['iowa', 'reversal', 'matrices', 'associates', 'wisconsin'],
  questionnaires: ['QN'],
  other: [],
};

const lookup = Object.entries(badgeGroups_).flatMap(([k, v]) => {
  return v.map(taskKey => [taskKey, k]);
});
const groupsByTaskKey_ = new Map(lookup);

export class BadgeGroup {
  static getGroupForTask(key) {
    return groupsByTaskKey_.get(key);
  }

  static taskKeysFromTaskKey(key) {
    const group = groupsByTaskKey_.get(key);

    return badgeGroups_[group] ?? [];
  }

  constructor({ dates = [], points = 0 } = {}) {
    this.dates = dates;
    this.points = points;
  }

  addPoints(points) {
    this.points += points;
    const added = Math.trunc(this.points) - this.dates.length;

    if (added > 0) {
      const now = new Date();
      for (let i = 0; i < added; i++) {
        this.dates.push(now);
      }
    }
  }
}
