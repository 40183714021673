import Vuex from 'vuex';

const { mapState, mapGetters, mapActions, mapMutations } = Vuex;
export const authComputed = {
  ...mapState('auth', {
    currentUser: state => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn', 'myRole', 'can']),
};

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'requestResetCode', 'loginWithCode']);

export const rbacComputed = {
  ...mapGetters('rbac', ['rbacCan']),
};

export const dataComputed = {
  ...mapState('data', {
    count: state => state.count,
    status: state => state.status,
  }),
};

export const dataMethods = mapActions('data', ['save']);

export const resultsComputed = {
  ...mapState('results', {
    currentResults: state => state.currentResults,
  }),
};

export const resultsMethods = mapActions('results', [
  'createSession',
  'clearResults',
  'setState',
  'pushToItems',
  'getAllResults',
  'deleteResults',
]);

export const themeMethods = {
  ...mapActions('theme', ['setTheme']),
};

export { mapState, mapGetters, mapMutations, mapActions };
