import loader from '../loader.js';
import store from '@state/store.js';
import taskRoutes from './task-routes.js';

export default [
  {
    path: '/reset-password/:code',
    component: () => loader.load('login/reset.js'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => loader.load('login/login.js'),
  },
  {
    path: '/login/sendreset',
    name: 'sendreset',
    component: () => loader.load('login/sendreset.js'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');
      const authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.meta.authRequired || true);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom });
    },
  },
  {
    path: '/details',
    name: 'mydetails',
    meta: {
      authRequired: true,
    },

    component: () => loader.load('login/my-details.js'),
  },
  {
    path: '/pwd',
    name: 'changepassword',
    meta: {
      authRequired: true,
    },

    component: () => loader.load('login/change-password.js'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('home/home.js'),
  },
  ...taskRoutes,

  {
    path: '/help',
    name: 'help',
    component: () => loader.load('help/help.js'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => loader.load('about/about.js'),
  },
  {
    path: '/options',
    name: 'options',
    component: () => loader.load('general/options.js'),
  },
  {
    path: '/info',
    name: 'info',
    component: () => loader.load('general/info.js'),
  },
  {
    path: '/points',
    name: 'points',
    component: () => loader.load('points/points.js'),
  },
  { path: '/:remainder*', redirect: { name: 'home' } },
  { path: '/app/:remainder*', redirect: { name: 'home' } },
];
