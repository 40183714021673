import Vuex, { mapMutations } from 'vuex';

const { mapState, mapGetters, mapActions } = Vuex;
const name_ = 'profile';

export const profileComputed = {
  ...mapState(name_, {
    taskInfo: state => state.tasks,
  }),
  ...mapGetters(name_, ['getTheme', 'getTask', 'getGroupBadgePoints', 'getBadgesByDate']),
};

export const profileMethods = {
  ...mapMutations(name_, ['updateBadgePoints']),
  ...mapActions(name_, ['setTheme', 'setTaskInfo', 'updateTaskInfo', 'getTotalPoints', 'getBadgeGroupCount']),
};
