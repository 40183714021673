import { mapActions, mapState, mapMutations } from 'vuex';

const name_ = 'results';
export const resultsComputed = {
  ...mapState(name_, {
    currentResults: state => state.currentResults,
    resultsState: state => state.resultsState,
    resultsDirty: state => state.dirty,
  }),
};

export const resultsMethods = {
  ...mapActions(name_, [
    'createResults',
    'clearResults',
    'setState',
    'pushToItems',
    'pushItem',
    'getAllResults',
    'deleteResults',
    'putResults',
    'putResultsState',
    'updateResultsState',
    'getResults',
  ]),
  ...mapMutations(name_, { setResultsDirty: 'set_dirty' }),
};
