import Vuex from 'vuex';

const { mapState, mapGetters, mapActions } = Vuex;

export const projectComputed = {
  ...mapState('project', {
    currentProject: state => state.currentProject,
    games: state => state.games,

    lastProjectId: state => state.lastProjectId,
  }),
  ...mapGetters('project', ['getGames', 'getGroupedGames', 'getProjectCode', 'getMaxBadges', 'getProjectId']),
};

export const projectMethods = mapActions('project', ['fetchProject']);
