import timestring from 'timestring';
import store from '@state/store.js';

let storage_;

export default class StateBase {
  state() {
    storage_ = new Storage();

    return {
      game: {},
      settings: {},
      taskId: '',
      resuming: false,
      score: 0,
      modelHtml: {},
      oldHiScore: 0,
      curScore: 0,
      earnedBadgePoints: false,
      badgeCount: 0,
    };
  }
  mutations() {
    return {
      setResuming(state, newValue) {
        state.resuming = newValue;
      },
      setTaskState(state, payload) {
        Object.entries(payload).forEach(([k, v]) => (state[k] = v));
      },

      setOldHiScore(state, nv) {
        state.oldHiScore = nv;
      },

      setBadgeCount(state, nv) {
        state.badgeCount = nv;
      },

      setCurScore(state, nv) {
        state.curScore = nv;
      },

      setTaskId(state, newValue) {
        state.taskId = newValue;
      },
      SET_SETTINGS(state, newValue) {
        state.settings = newValue;
      },
      setModelHtml(state, newValue) {
        state.modelHtml = newValue;
      },
      SET_GAME(state, newValue) {
        state.game = newValue;
      },
      incrementScore(state, newValue) {
        state.score += newValue;
      },
      resetScore(state) {
        state.score = 0;
      },
      setEarnedBadgePoints(state) {
        state.earnedBadgePoints = true;
      },

      updateState(state, [path, value]) {
        path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
          if (array.length === index + 1) {
            // this._vm.$set(prev, key, value);
            prev[key] = value;
          }

          return prev[key] !== void 0 ? prev[key] : (prev[key] = {});
        }, state);
      },
    };
  }
  getters() {
    return {
      maxPoints(state, getters) {
        const { pointsPerTrial = 0 } = state.settings.points || {};
        return pointsPerTrial * getters.numberOfTrials;
      },
      getTitle(state) {
        return (state.settings && state.settings.name) || '[Task name]';
      },
      getInstructions({ modelHtml, settings }) {
        return ([key, params]) => {
          const value =
            (modelHtml && modelHtml.instructions[key]) ||
            settings.instructions[key] ||
            settings.instructionsDefault[key];
          return fillTemplate(value, params);
        };
      },
      getTaskInfo(state, getters, rootState, rootGetters) {
        return rootGetters['profile/getTask'](state.game._id);
      },
      baseCheckpointItems: () => ({ score: 1, oldHiScore: 1, curScore: 1, earnedBadgePoints: 1, badgeCount: 1 }),
    };
  }
  actions() {
    return {
      async saveCheckpoint({ state, getters, dispatch }) {
        const items = { ...getters.baseCheckpointItems, ...getters.checkpointItems };
        if (items) {
          const { taskId } = state;
          await saveState(taskId, reducer(state, items), storage_, dispatch);

          // await dispatch('restoreCheckpoint');
        }
      },

      async restoreCheckpoint({ state, commit, dispatch }) {
        const { taskId } = state;
        const restored = await restoreState(taskId, storage_, dispatch);
        if (!restored) {
          return;
        }
        Object.entries(restored).forEach(([key, value]) => {
          commit('updateState', [key, value]);
          // this._vm.$set(state, key, value);
          // console.dir([key, value]);
        });

        return true;
      },

      async deleteCheckpoint({ state, commit, dispatch }) {
        const { taskId } = state;

        return await dispatch('results/deleteResultsCheckpoint', taskId, { root: true });
      },
      async updateTaskInfo({ state, commit, dispatch }, payload) {
        const params = Array.isArray(payload[0]) ? payload : [payload];
        params.forEach(op => commit('profile/updateTaskInfo', op, { root: true }));

        try {
          await dispatch('profile/saveTaskInfo', { taskId: state.game._id }, { root: true });
        } catch (e) {
          console.dir(e);
        }
      },
    };
  }

  modules() {
    return {};
  }

  getModule() {
    return {
      namespaced: true,
      state: () => this.state(),
      getters: this.getters(),
      actions: this.actions(),
      mutations: this.mutations(),
      modules: this.modules(),
      name: this.name,
    };
  }
}

export const fillTemplate = (templateString, templateVariables) =>
  templateString.replace(/{{(.*?)}}/g, (_, g) => templateVariables[g.trim()]);

function getDuration(value, units) {
  value = String(value);
  const groups = value
    .toLowerCase()
    .replace(/[^.\w+-]+/g, '')
    .match(/[-+]?[0-9.]+[a-z]+/g);
  if (!groups) {
    value = `${value}${units}`;
  }

  return timestring(value, 'ms');
}

export function fixupDurations(fields, durations) {
  const res = { ...durations };
  fields.forEach(item => {
    let units = 's';
    if (Array.isArray(item)) {
      [item, units] = item;
    }
    item.split(/[.[\]]+/).reduce((prev, key, index, array) => {
      const previous = prev[key];

      if (array.length === index + 1) {
        previous != void 0 && (res[key] = getDuration(previous, units));
      }

      return previous;
    }, durations);
  });
  return res;
}

export function registerModule(taskStore) {
  const { name } = taskStore;
  if (!(store.state && store.state[name])) {
    store.registerModule(name, taskStore);
    // console.log(`registerModule: ${name}`);
  } else {
    // re-use the already existing module
    console.log(`reusing module: ${name}`);
  }
}
export function unregisterModule(name) {
  if (store && store.state && store.state[name]) {
    store.unregisterModule(name);
    // console.log(`unregisterModule: ${name}`);
  }
}

// ===
// Private functions
// ===

class Storage {
  constructor() {
    this.items = {};
  }

  async setItem(key, state) {
    this.items[key] = state;
  }

  async getItem(key) {
    return this.items[key];
  }
}

const restoreState = async (key, storage, dispatch) => {
  storage.getItem(key).then(value =>
    typeof value === 'string' // If string, parse, or else, just return
      ? JSON.parse(value || '{}')
      : value || {},
  );

  return await dispatch('results/getResultsCheckpoint', key, { root: true });
};
const saveState = async (key, state, storage, dispatch) => {
  storage.setItem(key, { ...state });
  await dispatch('results/putResultsCheckpoint', { key, value: state }, { root: true });
};

const reducer = (state, fields) => {
  const reduced = Object.keys(fields).reduce((a, i) => ({ ...a, [i]: state[i] }), {});

  return reduced;
};
