import initApp from './home/init-app.js';

export default {
  data() {
    return {
      isReady: false,
      haveFetchProject: false,
    };
  },
  render(h) {
    return h('div', { attrs: { id: 'app' } }, [
      this.showProgress
        ? h(initApp, { on: { 'init-app': ({ type }) => this.onInitApp(type) } }, {})
        : h('router-view', { attrs: { key: this.$route.fullPath } }, {}),

      h('mdc-snackbar-queue', {
        ref: 'snackbar',
        class: ['onc-snackbar'],
      }),
    ]);
  },
  computed: {
    showProgress() {
      return !(this.isReady && this.haveFetchProject);
    },
  },
  mounted() {
    this.$store.commit('setSnackbarHandler', this.onSnack);

    // delay so check details is seen (probably remove for production)

    setTimeout(
      () => {
        this.isReady = true;
      },
      location.hostname == 'localhost' ? 500 : 2000,
    );
  },

  methods: {
    onInitApp(type) {
      // we wait until have tried to fetch a project
      switch (type) {
        case 'fetchProject':
          this.haveFetchProject = true;
          break;
      }
    },
    onSnack(snack) {
      const snackbar = this.$refs.snackbar;
      if (snackbar) {
        snackbar.handleSnack(snack);
      }
    },
  },
};
