/* eslint quote-props: 0 */
import jwtDecode from 'jwt-decode';
import { defaults as apiDefaults } from '../api.js';
import ky from 'ky';

const namespaced = true;

export default {
  namespaced,
  state: {
    currentUser: getSavedState('auth.currentUser'),
    currentRbac: null,
  },
  mutations: {
    SET_CURRENT_USER(state, newValue = null) {
      const { accessToken } = newValue || {};
      state.currentUser = newValue;
      saveState('auth.currentUser', newValue);
      setDefaultAuthHeaders(accessToken);
    },
    SET_CURRENT_RBAC(state, newValue) {
      state.currentRbac = newValue;
    },
  },
  getters: {
    // Whether the user is currently logged in.
    loggedIn(state) {
      return !!state.currentUser;
    },
    myRole(state) {
      return state.currentUser && state.currentUser.roles.role;
    },
    myTeams(state) {
      return state.currentUser && state.currentUser.roles.teams;
    },
    can: state => (operation, params) => {
      return (
        state.currentRbac.can &&
        state.currentUser &&
        state.currentRbac.canSync(state.currentUser.roles.role, operation, params)
      );
    },
    userId(state) {
      return state.currentUser && state.currentUser.userref;
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch, getters }) {
      setDefaultBeforeFetch({ state, dispatch, getters });
      return dispatch('validate');
    },

    perms({ commit }, rbac) {
      commit('SET_CURRENT_RBAC', rbac);
    },
    // Logs in the current user.
    async logIn({ commit, dispatch, getters }, { username, password } = {}) {
      const { user = null } = await ky
        .post('/token', {
          json: {
            username,
            password,
          },
        })
        .json();
      if (user) {
        if (!user.name) {
          user.name = 'n/a';
        }
        const {
          payload: { userref, ...roles },
          ...info
        } = user;
        info.userref = userref;
        info.tokenData = getPayload(user.accessToken);
        info.roles = roles;
        commit('SET_CURRENT_USER', info);
        return info;
      }
    },

    async loginWithCode({ commit, dispatch, getters }, { password, confirm, code } = {}) {
      await ky.put(`/api/reset/${code}`, { json: { password, confirm } });
    },

    async requestResetCode({ commit, dispatch, getters }, { subject } = {}) {
      const isAdmin = location.pathname.startsWith('/admin');

      await ky.post(`/api/send-reset-email?client=${isAdmin ? '0' : '1'}`, { json: { subject } });
    },

    // Logs out the current user.
    async logOut({ state, commit }) {
      const { accessToken } = state.currentUser;
      await ky.post(`/logout`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      });
      commit('SET_CURRENT_USER', null);
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state }) {
      const user = state.currentUser;
      if (!user) {
        return Promise.resolve(null);
      }

      const { accessToken } = user;

      setDefaultAuthHeaders(accessToken);
      return Promise.resolve(state.currentUser);
    },
  },
};

// ===
// Private functions
// ===

function getPayload(token) {
  if (token) {
    return jwtDecode(token);
  }
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function setDefaultAuthHeaders(accessToken) {
  apiDefaults.headers.common.Authorization = accessToken ? `Bearer ${accessToken}` : '';
}

function setDefaultBeforeFetch({ state, dispatch, getters }) {
  apiDefaults.beforeFetch = request => {
    // If auth is required and the user is logged in...
    // validate (which will refresh the token as necessary)
    if (getters['loggedIn']) {
      return dispatch('validate');
    }
    return Promise.resolve();
  };
}
