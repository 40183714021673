import api from '@state/api.js';
import { Store } from '../../idb-db.js';
import Vue from 'vue';
import { BadgeGroup } from './badge-group.js';

let store_;

export default {
  namespaced: true,
  state: {
    tasks: {},
    badges: {},
    meta: { theme: '' },
  },
  mutations: {
    setMeta(state, newValue) {
      state.meta = Object.assign({ theme: 'board' }, newValue);
    },
    setTasks(state, newValue) {
      state.tasks = Object.assign({}, newValue);
    },
    setBadges(state, newValue) {
      state.badges = Object.assign({}, newValue);
    },

    updateBadgePoints(state, { taskId, badgePoints }) {
      const { key } = state.tasks[taskId];
      const groupKey = BadgeGroup.getGroupForTask(key);

      const badgeGroup = state.badges[groupKey] ?? (state.badges[groupKey] = new BadgeGroup());
      badgeGroup.addPoints(badgePoints);
    },

    setTaskInfo(state, { taskId, value }) {
      Vue.set(state.tasks, taskId, value);
    },
    SET_THEME(state, newValue) {
      state.meta.theme = newValue;
    },

    updateTaskInfo(state, [path, value]) {
      path.split(/[.[\]]+/).reduce((prev, key, index, array) => {
        if (array.length === index + 1) {
          // eslint-disable-next-line no-param-reassign
          prev[key] = value;
        }

        return prev[key] !== void 0 ? prev[key] : (prev[key] = {});
      }, state.tasks);
    },
  },
  getters: {
    getTheme(state) {
      return state.meta.theme || 'board';
    },
    getTask: state => taskId => {
      const task = state.tasks[taskId];

      return task;
    },

    getGroupBadgePoints: state => taskIdOrBadgeGroup => {
      const task = state.tasks[taskIdOrBadgeGroup];

      const taskKeys = BadgeGroup.taskKeysFromTaskKey(task.key);

      const groupPoints = Object.values(state.tasks).reduce((p, { badgePoints, key }) => {
        if (taskKeys.indexOf(key) > -1) {
          return p + badgePoints;
        }
        return p;
      }, 0);

      return groupPoints;
    },

    getBadgesByDate(state) {
      const badges = state.badges;

      const items = Object.entries(badges).flatMap(([key, { dates }]) => {
        return dates.map((date, i) => [i, date, key]);
      });

      // console.dir(items);
      return items;
    },
  },
  actions: {
    getBadgeGroupCount({ state }, taskKey) {
      const groupKey = BadgeGroup.getGroupForTask(taskKey);

      const { dates = [] } = state.badges[groupKey] ?? {};
      return { groupKey, badgeCount: dates.length };
    },

    setTheme({ commit, dispatch, rootState, rootGetters }, newValue) {
      commit('SET_THEME', newValue);
      const projectId = rootGetters['project/getProjectId'];
      const { userref } = rootState.auth.currentUser;
      const value = {
        operation: 'theme-update',
        projectId,
        theme: newValue,
        userref,
      };
      dispatch('data/save', { value }, { root: true });
    },
    async init({ state }) {
      store_ = new Store('profile');
      await store_.set('profile', '2');
    },
    async fetchProfile({ state, commit, rootGetters }) {
      const projectId = rootGetters['project/getProjectId'];
      if (projectId) {
        try {
          const { meta = {}, tasks = {}, badges: badgeData = {} } =
            (await api.get(`/api/profile/${projectId}`).json()) || {};
          commit('setMeta', meta);
          commit('setTasks', tasks);

          const badges = Object.fromEntries(
            Object.entries(badgeData).map(([key, value]) => {
              return [key, new BadgeGroup(value)];
            }),
          );

          commit('setBadges', badges);
        } catch (e) {
          console.dir(e);
        }
      }
    },

    async getTotalPoints({ state }) {
      return Object.values(state.tasks).reduce((p, { hiScore = 0 }) => p + hiScore, 0);
    },

    async saveTaskInfo({ state, getters, rootState, dispatch, rootGetters }, { taskId }) {
      const projectId = rootGetters['project/getProjectId'];

      // await api.put(`/api/profile/${projectId}`, { json: { taskId, value: state.tasks[taskId] } });

      const { accessToken: token, userref } = rootState['auth']['currentUser'];
      const totalPoints = Object.values(state.tasks).reduce((p, { hiScore = 0 }) => p + hiScore, 0);

      const value = {
        operation: 'profile-save',
        taskId,
        taskinfo: state.tasks[taskId],
        badges: state.badges,
        userref,
        projectId,
        totalPoints,
      };

      await dispatch('data/save', { token, value }, { root: true });
    },
  },
};
