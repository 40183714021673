import { objectID } from 'onc';
import { openDB } from 'idb';

// note: this version should be incremented if stores are added/removed from the database.
const dbVersion_ = 1;

export default {
  namespaced: true,
  state: {
    currentResults: null,
    dirty: false,
    dbStore: null,
  },
  mutations: {
    SET_CURRENT_RESULTS(state, newValue) {
      state.currentResults = newValue;
      state.dirty = true;
    },

    SET_DBSTORE(state, newValue) {
      state.dbStore = newValue;
    },

    SET_STATE(state, newValue) {
      const { currentResults } = state;

      state.dirty = true;
      if (typeof newValue == 'string') {
        currentResults.state = newValue;
        currentResults.disposition = newValue;
      } else {
        const { state: taskState, disposition } = newValue;
        taskState && (currentResults.state = taskState);
        currentResults.disposition = disposition ?? taskState;
      }
    },

    set_dirty(state, newValue) {
      state.dirty = newValue;
    },
  },

  actions: {
    async init({ commit }) {
      const dbStore = await initDb('results');
      commit('SET_DBSTORE', dbStore);
    },

    async pushToItems({ state, commit, dispatch }, newValue) {
      const results = state.currentResults;

      results.items.push(newValue);

      commit('set_dirty', true);
      await dispatch('putResults', results);
    },

    async setState({ state, commit, dispatch }, newValue) {
      commit('SET_STATE', newValue);
      await dispatch('putResults', state.currentResults);
    },

    async pushItem({ state, dispatch }, item) {
      return dispatch('pushToItems', item);
    },

    async getResultsCheckpoint({ state }, key) {
      const dbStore = state.dbStore;
      const taskKey = `${key}-state`;

      const entry = await await dbStore.get('results', taskKey);
      if (entry) {
        // eslint-disable-next-line no-unused-vars
        const { taskKey, ...rest } = entry;
        return rest;
      }
    },

    async putResultsCheckpoint({ state, commit }, { key, value }) {
      const dbStore = state.dbStore;
      const taskKey = `${key}-state`;

      await dbStore.put('results', { ...value, taskKey });
    },

    async deleteResultsCheckpoint({ state }, key) {
      const dbStore = state.dbStore;
      const taskKey = `${key}-state`;

      await dbStore.delete('results', taskKey);
    },

    async getResults({ state, commit }, taskKey) {
      const db = state.dbStore;
      const results = await db.get('results', taskKey);
      commit('SET_CURRENT_RESULTS', results);
      return results;
    },

    async putResults({ state }, payload) {
      const db = state.dbStore;
      await db.put('results', payload);
    },

    async createResults(
      { commit, dispatch },
      { userref, sui, sessionInfo, projectId, taskId, taskKey, state: taskState },
    ) {
      const session = {
        userref,
        sui,
        taskId,
        taskKey,
        projectId,
        state: taskState,
        disposition: '',
        created: new Date(),
        sessionId: objectID().toString(),
        sessionInfo,
        items: [],
      };

      commit('SET_CURRENT_RESULTS', session);

      await dispatch('putResults', session);
      return session;
    },

    async getAllResults({ state }) {
      const db = state.dbStore;
      const allResults = [];
      let cursor = await db.transaction('results').store.openCursor();
      while (cursor) {
        allResults.push(cursor.value);
        cursor = await cursor.continue();
      }

      return allResults.length > 0 ? allResults : null;
    },

    async deleteResults({ state, commit }, taskKey) {
      const db = state.dbStore;
      commit('set_dirty', false);
      await db.delete('results', taskKey);
    },

    async clearResults({ state, commit }) {
      const results = state.currentResults;
      const db = state.dbStore;
      const { taskKey } = results;

      commit('set_dirty', false);
      await db.delete('results', taskKey);
      await db.delete('results', `${taskKey}-state`);
    },
  },
};

// ===
// Private functions
// ===

async function initDb(storeName = 'dbresults') {
  return openDB(storeName, dbVersion_, {
    upgrade(db) {
      const store = db.createObjectStore('results', { keyPath: 'taskKey' });
      store.createIndex('taskKey', 'taskKey');
    },
    blocked() {
      console.log('Database blocked: Please close all other tabs with this site open.');
    },
    blocking() {
      console.log('db version change detected Jim...');
    },
  });
}
